import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SabpaisaPaymentGateway from './SabpaisaPaymentGateway';
import { QRCodeCanvas } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { jsPDF } from "jspdf";

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginBottom: '20px' }}>
    <div className="spinner"></div>
    <style>{`
      .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #38a169;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);

const App = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clientCode, setClientCode] = useState("");
  const [transUserName, setTransUserName] = useState("");
  const [transUserPassword, setTransUserPassword] = useState("");
  const [authkey, setAuthkey] = useState("");
  const [authiv, setAuthiv] = useState("");
  const [pgName, setPgName] = useState("");
  const [payerName, setPayerName] = useState("");
  const [/*payerEmail*/, setPayerEmail] = useState("");
  const [/*payerMobile*/, setPayerMobile] = useState("");
  const [clientTxnId, setClientTxnId] = useState(() => localStorage.getItem('clientTxnId') || "");
  const [amount, setAmount] = useState(null);
  const [payerAddress] = useState("");
  const callbackUrl = "http://localhost:3000/response";
  const [searchKey, setSearchKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(() => JSON.parse(localStorage.getItem('isModalOpen')) || false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState(() => JSON.parse(localStorage.getItem('deviceData')) || null);
  const [error, setError] = useState("");
  const [notification, setNotification] = useState("");
  const [addedDevices, setAddedDevices] = useState(() => JSON.parse(localStorage.getItem('addedDevices')) || []);
  const [totalAmount, setTotalAmount] = useState(() => JSON.parse(localStorage.getItem('totalAmount')) || 0);
  const [userIP, setUserIP] = useState("");
  const [/*mid*/, setMid] = useState("");
  const [responseModal, setResponseModal] = useState({ open: false, message: "", paymentLink: "" });
  const [paymentStatusModal, setPaymentStatusModal] = useState({ open: false, message: "", data: [] });
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]); // Track selected device IDs
  const [qrCodeModal, setQrCodeModal] = useState({ open: false, upiLink: "" });
  const [qrCodeStatusModal, setQrCodeStatusModal] = useState({ open: false, message: "", data: null });
  const [pollingInterval, setPollingInterval] = useState(null);
  const [amountconfirmforgateway, setamountconfirmforgateway] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({
    actualAmount: 0,
    finalAmount: 0,
  });
  const [amountconfirmforgeneratelink, setamountconfirmforgeneratelink] = useState(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    message: ''
  });
  



  useEffect(() => {
    localStorage.setItem('addedDevices', JSON.stringify(addedDevices));
    localStorage.setItem('totalAmount', JSON.stringify(totalAmount));
    localStorage.setItem('isModalOpen', JSON.stringify(isModalOpen));
    localStorage.setItem('deviceData', JSON.stringify(deviceData));
  }, [addedDevices, totalAmount, isModalOpen, deviceData]);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    };

    fetchIP();
  }, []);

  const handlePaymentNow = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/getPaymentCompany?paymentCompany=SubPaisa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentCompany: 'SubPaisa',
        }),
      });
  
      const data = await response.json();
  
      setLoading(false);
  
      if (data.status) {
        setClientCode(data.data.ClientCode);
        setTransUserName(data.data.UserName);
        setTransUserPassword(data.data.Password);
        setAuthkey(data.data.AuthenticationKey);
        setAuthiv(data.data.AuthenticationIV);
        setPgName(data.data.pgName);
        setIsPaymentModalOpen(true);
      } else {
        setErrorModal({
          open: true,
          message: data.message || "An error occurred while fetching payment company data."
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching payment company data:", error);
      setErrorModal({
        open: true,
        message: "An error occurred while fetching payment company data."
      });
    }
  };

  const handlePaymentGatewayClick = async () => {
    const midValue = localStorage.getItem('mid');
    if (userIP && addedDevices.length > 0 && deviceData) {
      const vehicleInfoDTO = addedDevices.map(device => ({
        expiryDate: device.expiry,
        did: device.deviceid,
        deviceName: device.devicename,
      }));

      const server = addedDevices[0].server;
      const requestBody = {
        amount: totalAmount,
        pgName: pgName,
        mid: midValue,
        vehicleInfoDTO: vehicleInfoDTO,
        ip: userIP,
        server: server,
        source: "web",
        clientNumber: Mobile
      };

      try {
        const response = await fetch('https://staffnew.saharshsolutions.co.in:2083/pmcontroller/saveVehicleDetailsForPayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (data.status) {
          const orderId = data.data.orderId;  // Extract orderId
          const actualAmount = data.data.actualAmount;
          const finalAmount = data.data.finalAmount;

          setClientTxnId(orderId);  // Set clientTxnId with the orderId
          setAmount(totalAmount); // Set the amount from totalAmount

          // Store the actual and final amounts in the state
          setVehicleDetails({
            actualAmount,
            finalAmount,
          });

          // Open the UserInfo modal to show these details
          setamountconfirmforgateway(true);
        } else {
          setError(formatErrorMessage(data.message));
        }
      } catch (error) {
        console.error("Error saving vehicle details:", error);
        setError("An error occurred while saving vehicle details.");
      }
    } else {
      setError("Unable to proceed with payment. Please check your network and try again.");
    }
  };

  const handleGenerateLink = async () => {
    const midValue = localStorage.getItem('mid');
    if (userIP && addedDevices.length > 0 && deviceData) {
      const vehicleInfoDTO = addedDevices.map(device => ({
        expiryDate: device.expiry,
        did: device.deviceid,
      }));

      const server = addedDevices[0].server;
      const requestBody = {
        amount: totalAmount,
        pgName: pgName,
        mid: midValue,
        vehicleInfoDTO: vehicleInfoDTO,
        ip: userIP,
        server: server,
        source: "web",
        clientNumber: Mobile
      };

      try {
        const response = await fetch('https://staffnew.saharshsolutions.co.in:2083/pmcontroller/saveVehicleDetailsForPayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (data.status) {
          const orderId = data.data.orderId;  // Extract orderId
          setClientTxnId(orderId);  // Set clientTxnId with the orderId

          // Get the actual and final amounts from saveVehicleDetails API response
          const actualAmount = data.data.actualAmount;
          const finalAmount = data.data.finalAmount;

          // Now, trigger the payment link API
          const paymentLinkResponse = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/getPaymentLink?amount=${finalAmount}&orderId=${orderId}&purpose=renewal&payerName=${payerName}&payerAccountNumber=2143123123`);

          const paymentLinkData = await paymentLinkResponse.json();

          if (paymentLinkData.status) {
            const paymentLink = paymentLinkData.data.paymentLink;
            const message = paymentLinkData.data.message;

            // Set the modal state with the new data
            setResponseModal({
              open: false, // Keep it closed until Proceed to Pay is clicked
              message: message, // Show the message
              paymentLink: paymentLink // Show the payment link
            });

            // Store the actual and final amounts for display in the modal
            setVehicleDetails({
              actualAmount: actualAmount,
              finalAmount: finalAmount,
            });

            // Open the amount confirmation modal for the generated link
            setamountconfirmforgeneratelink(true);
          } else {
            setError(formatErrorMessage(paymentLinkData.message));
          }
        } else {
          setError(formatErrorMessage(data.message));
        }
      } catch (error) {
        console.error("Error generating payment link:", error);
        setError("An error occurred while generating the payment link.");
      }
    } else {
      setError("Unable to generate payment link. Please check your network and try again.");
    }
  };

  // UseEffect to monitor and log clientTxnId and amount
  useEffect(() => {
    if (clientTxnId) {
      localStorage.setItem('clientTxnId', clientTxnId);
      // console.log('Client clientTxnId ID after setting:', clientTxnId);
    }
  }, [clientTxnId]);

  useEffect(() => {
    if (amount !== null) {
      // console.log('Amount after setting:', amount);
    }
  }, [amount]);

  const formatErrorMessage = (message) => {
    return message.replace(/_/g, ' ').toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  };

  const openModal = async (e) => {
    setError("");
    setNotification("");
  
    if (!searchKey.trim()) { // Check if the search term is empty
      setError("Search term cannot be empty.");
      return;
    }
  
    setIsModalOpen(true);
    setLoading(true);
  
    try {
      const response = await fetch(`https://staffnew.saharshsolutions.co.in:2083/userPay/getDeviceForPayment/${searchKey}`);
      const data = await response.json();
  
      setLoading(false);
  
      if (data.status) {
        setDeviceData(data.data[0]);
  
        // Set the first device's email and mobileNo into the states
        const { email, mobileNo } = data.data[0]; // Assuming the device has email and mobile number
        setPayerEmail(email); // Store email in payerEmail state
        setPayerMobile(mobileNo); // Store mobileNo in payerMobile state
  
        // Store email and mobileNo in localStorage
        localStorage.setItem('payerEmail', email); // Store email in localStorage
        localStorage.setItem('payerMobile', mobileNo); // Store mobileNo in localStorage
  
        const midValue = data.data[0].mid;
        setMid(midValue); // Set mid value here
        localStorage.setItem('mid', midValue); // Store mid in localStorage
        // console.log("MID set to:", midValue); // Log the mid value
      } else {
        setDeviceData(null);
        setError(formatErrorMessage(data.message));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [decryptedUsername, setDecryptedUsername] = useState('');

  // Function to get query parameters
  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  // Function to decrypt the username
  const decryptUsername = (encryptedUsername) => {
    const key = "PoGKUI!@#$%^$#*()BoXHPPATEL";
    const bytes = CryptoJS.AES.decrypt(encryptedUsername, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // Extract and decrypt the username from URL
  useEffect(() => {
    const encryptedUsername = getQueryParam('username');
    if (encryptedUsername) {
      const decrypted = decryptUsername(encryptedUsername);
      setDecryptedUsername(decrypted);
    }
  }, [location.search]); // Trigger when the URL changes

  const openModalforuser = useCallback(async (e) => {
    setError("");
    setNotification("");

    const searchKeyToUse = searchKey.trim() || decryptedUsername.trim();
    if (!searchKeyToUse) {
      setError("Search term cannot be empty.");
      return;
    }

    setIsModalOpen(true);
    setLoading(true);

    try {
      const response = await fetch(`https://staffnew.saharshsolutions.co.in:2083/userPay/getDeviceListFromUser/${searchKeyToUse}`);
      const data = await response.json();

      setLoading(false);

      if (data.status && data.data.length > 0) {
        setDeviceData(data.data); // Store all devices in deviceData

        // Set the first device's email and mobileNo into the states
        const { email, mobileNo } = data.data[0]; // Assuming all devices have the same email and mobile number
        setPayerEmail(email); // Store email in payerEmail state
        setPayerMobile(mobileNo); // Store mobileNo in payerMobile state

        // Store email and mobileNo in localStorage
        localStorage.setItem('payerEmail', email); // Store email in localStorage
        localStorage.setItem('payerMobile', mobileNo); // Store mobileNo in localStorage

        const midValue = data.data[0].mid;
        setMid(midValue); // Set mid value from the first device
        localStorage.setItem('mid', midValue); // Store mid in localStorage

        // Refresh the page and navigate to the main URL
        window.location.replace(window.location.origin);
      } else {
        setDeviceData(null);
        setError(formatErrorMessage(data.message || "No devices found."));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
    }
  }, [searchKey, decryptedUsername]);

  useEffect(() => {
    const storedPayerName = localStorage.getItem('payerName');
    if (storedPayerName) {
      setPayerName(storedPayerName);
    }

    // Automatically trigger openModalforuser if decryptedUsername is available
    if (decryptedUsername) {
      openModalforuser();
    }
  }, [isModalOpen, openModalforuser, decryptedUsername]);

  const handleSelectDevice = (deviceId) => {
    setSelectedDeviceIds((prevSelectedDeviceIds) => {
      if (prevSelectedDeviceIds.includes(deviceId)) {
        return prevSelectedDeviceIds.filter(id => id !== deviceId);
      } else {
        return [...prevSelectedDeviceIds, deviceId]; // Select the device
      }
    });
  };

  const addDeviceToTable = () => {
    if (deviceData) {
      let selectedDevices = [];

      // If there are selected devices, add only those; otherwise, add all devices (single or multiple)
      if (selectedDeviceIds.length > 0) {
        selectedDevices = Array.isArray(deviceData)
          ? deviceData.filter(device => selectedDeviceIds.includes(device.deviceid))
          : [deviceData].filter(device => selectedDeviceIds.includes(device.deviceid)); // Handle single device case
      } else {
        // If no devices were selected, add the entire list (for multiple devices) or single device
        selectedDevices = Array.isArray(deviceData) ? deviceData : [deviceData];
      }

      if (selectedDevices.length === 0) {
        setNotification("No vehicles selected.");
        return;
      }

      // Filter out duplicates
      const newDevices = selectedDevices.filter(device =>
        !addedDevices.some(addedDevice => addedDevice.devicename === device.devicename)
      );

      if (newDevices.length === 0) {
        setNotification("Selected vehicles are already in the list.");
      } else {
        // Check for different server
        const differentServers = newDevices.some(device =>
          addedDevices.length > 0 && addedDevices[0].server !== device.server
        );

        if (differentServers) {
          setNotification("You cannot add vehicles from different servers at the same time. Please complete the process for the current server before adding vehicles from another server.");
        } else {
          // Check for different names
          const differentNames = newDevices.some(device =>
            addedDevices.length > 0 && addedDevices[0].name !== device.name
          );

          if (differentNames) {
            setNotification("You cannot add a vehicle with a different name. Please complete the process for the current name before adding vehicles with a different name.");
          } else {
            // Add new devices to the list
            setAddedDevices((prevDevices) => {
              const updatedDevices = [...prevDevices, ...newDevices];
              setPayerName(newDevices[0].name); // Set payerName to the name of the first device
              localStorage.setItem('payerName', newDevices[0].name); // Store payerName in localStorage
              return updatedDevices;
            });
            setIsModalOpen(false); // Close modal after adding devices
          }
        }
      }
    }
  };

  const removeDeviceFromTable = (deviceId) => {
    setAddedDevices((prevDevices) => prevDevices.filter(device => device.deviceid !== deviceId));
  };

  useEffect(() => {
    const total = addedDevices.reduce((acc, device) => acc + parseFloat(device.rate || 0), 0);
    setTotalAmount(total);
  }, [addedDevices]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientCode = queryParams.get('clientCode');

    // Retrieve clientTxnId from localStorage
    const storedClientTxnId = localStorage.getItem('clientTxnId');

    // console.log("URL Params:", clientCode, encResponse);
    // console.log("clientTxnId:", storedClientTxnId);

    if (clientCode && storedClientTxnId) {
      // console.log("Navigating to main page...");
      navigate('/', { replace: true });

      setTimeout(() => {
        // console.log("Triggering payment verification...");
        const verifyPayment = async () => {
          try {
            const response = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/paymentVerification?orderId=${storedClientTxnId}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            const data = await response.json();
            // console.log("Payment Verification Response:", data);

            // Handle dynamic message from the API response
            if (data.status) {
              setPaymentStatusModal({
                open: true,
                message: data.message || "Transaction Successful",  // Use the message from the API
                data: data.data || [],  // Handle dynamic data if present
              });
              // If transaction is successful, proceed to fetch the receipt data
              if (data.message === "Transaction Successful" && data.data && data.data.length > 0) {
                // Extract nextDate and save it to localStorage
                const nextDate = data.data[0]?.NextDate || "N/A";
                localStorage.setItem('nextDate', nextDate);
  
                // Fetch receipt data
                const receiptResponse = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/getAllByorderId?orderid=${storedClientTxnId}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
  
                if (receiptResponse.ok) {
                  const receiptData = await receiptResponse.json();
                  if (receiptData.status && receiptData.data) {
                    // console.log("Receipt data fetched:", receiptData.data);
                    localStorage.setItem('receiptData', JSON.stringify(receiptData.data));
                  }
                }
              }
            } else {
              setPaymentStatusModal({
                open: true,
                message: data.message || "Transaction Not Completed",  // Use the message from the API
                data: data.data || {},  // Still pass data even for failed transactions
              });
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            setPaymentStatusModal({
              open: true,
              message: "An error occurred while verifying the payment.",
              data: [],
            });
          }
        };

        verifyPayment();
      }, 1000);
    }
  }, [location.search, navigate]);

  const downloadReceiptforsabpaisa = () => {
    // Retrieve receipt data
    const receiptData = JSON.parse(localStorage.getItem('receiptData'));
    const nextExpiry = localStorage.getItem('nextDate') || "N/A";
  
    // console.log("Download receipt called with:", receiptData, nextExpiry);
  
    // Check if receiptData is valid
    if (!receiptData) {
      console.error("No receipt data found.");
      return;
    }
  
    const doc = new jsPDF();
  
    // Add header
    doc.setFontSize(18);
    doc.setTextColor(40);
    doc.setFont("helvetica", "bold");
    doc.text("Saharsh Solutions Payment Receipt", 105, 20, null, null, "center");
  
    // Add a horizontal line under the header
    doc.setLineWidth(0.5);
    doc.line(20, 25, 190, 25);
  
    // Add invoice metadata section
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    let metadataStartY = 35;
  
    const metadata = [
      ["Order ID", receiptData.orderId || "N/A"],
      ["Transaction Date", receiptData.bankTransactionDate || "N/A"],
      ["Amount", `${receiptData.finalAmount ? receiptData.finalAmount.toFixed(2) : "N/A"}`],
    ];
  
    metadata.forEach(([key, value]) => {
      doc.text(`${key}:`, 20, metadataStartY);
      doc.text(value, 70, metadataStartY);
      metadataStartY += 10;
    });
  
    // Add a horizontal line under metadata
    doc.setLineWidth(0.2);
    doc.line(20, metadataStartY - 5, 190, metadataStartY - 5);
  
    // Add Vehicle Details section
    let vehicleStartY = metadataStartY + 10;
  
    doc.setFont("helvetica", "bold");
    doc.text("Vehicle Details", 20, vehicleStartY);
    vehicleStartY += 8;
  
    // Retrieve and iterate over the vehicle list
    const vehicleList = receiptData.vehicleInfoDTOList || [];
    if (vehicleList.length === 0) {
      doc.setFont("helvetica", "normal");
      doc.text("No vehicles available", 20, vehicleStartY);
      vehicleStartY += 10;
    } else {
      doc.setFont("helvetica", "normal");
  
      vehicleList.forEach((vehicle, index) => {
        const formattedOldExpiryDate = vehicle.expiryDate
          ? new Date(vehicle.expiryDate).toISOString().split("T")[0]
          : "N/A";
  
        const vehicleData = [
          ["Vehicle Name", vehicle.deviceName || "N/A"],
          ["Old Expiry Date", formattedOldExpiryDate],
          ["Next Expiry", nextExpiry],
        ];
  
        vehicleData.forEach(([key, value]) => {
          doc.text(`${key}:`, 20, vehicleStartY);
          doc.text(value, 70, vehicleStartY);
          vehicleStartY += 10;
        });
  
        if (index < vehicleList.length - 1) {
          // Add a separator line between vehicles
          doc.line(20, vehicleStartY - 5, 190, vehicleStartY - 5);
          vehicleStartY += 5;
        }
      });
    }
  
    // Draw a border around the main content
    const contentStartY = 28;
    const contentHeight = vehicleStartY - contentStartY + 5;
    doc.setLineWidth(0.5);
    doc.rect(15, contentStartY, 180, contentHeight);
  
    // Add footer
    const footerY = vehicleStartY + 20;
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text("Thank you for your payment!", 105, footerY, null, null, "center");
  
    // Save the PDF
    doc.save("receipt.pdf");
  };
  
  const qrCodeRef = useRef();

  const downloadQRCodeAsImage = () => {
    const canvas = qrCodeRef.current.querySelector('canvas'); // Access the canvas element of the QR code
    const padding = 20; // Define padding (space around the QR code)
    
    const tempCanvas = document.createElement('canvas'); // Create a temporary canvas
    const context = tempCanvas.getContext('2d'); // Get the context to draw on
  
    // Set canvas dimensions with padding
    tempCanvas.width = canvas.width + padding * 2;
    tempCanvas.height = canvas.height + padding * 2;
  
    // Draw a white rectangle as the background (with padding)
    context.fillStyle = '#ffffff'; // Set fill color to white
    context.fillRect(0, 0, tempCanvas.width, tempCanvas.height); // Fill the entire canvas with white
  
    // Draw the QR code in the center of the canvas with padding
    context.drawImage(canvas, padding, padding);
  
    // Convert the canvas to a JPEG image
    const image = tempCanvas.toDataURL('image/jpeg', 1.0);
  
    // Trigger download
    const link = document.createElement('a');
    link.href = image;
    link.download = 'qrcode.jpg'; // Specify the file name for the download
    link.click(); // Trigger the download
  };
  
  const Mobile = localStorage.getItem("payerMobile");
  
  const handleBhimQrClick = async () => {
    const midValue = localStorage.getItem('mid');
    if (userIP && addedDevices.length > 0 && deviceData) {
      const vehicleInfoDTO = addedDevices.map(device => ({
        expiryDate: device.expiry,
        did: device.deviceid,
        deviceName: device.devicename,
      }));
  
      const server = addedDevices[0].server;
      const requestBody = {
        amount: totalAmount,
        pgName: "icici",
        mid: midValue,
        vehicleInfoDTO: vehicleInfoDTO,
        ip: userIP,
        server: server,
        source: "web",
        clientNumber: Mobile
      };
  
      try {
        const response = await fetch('https://staffnew.saharshsolutions.co.in:2083/pmcontroller/saveVehicleDetailsForPayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        const data = await response.json();
  
        if (data.status) {
          const orderId = data.data.orderId;
          setClientTxnId(orderId);  // Set clientTxnId with the orderId
  
          // Trigger the second API to get the UPI payment link
          const upiResponse = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/getPaymentLinkIcici?amount=${totalAmount}&usertxid=${orderId}`, {
            method: 'GET',
          });
  
          const upiData = await upiResponse.json();
  
          if (upiData.status) {
            const upiLink = upiData.data.upiLink;
  
            // Open the QR code modal and pass the UPI link
            setQrCodeModal({
              open: true,
              upiLink: upiLink
            });
          } else {
            setErrorModal({
              open: true,
              message: upiData.message || "Failed to generate UPI payment link."
            });
          }
        } else {
          setErrorModal({
            open: true,
            message: data.message || "An error occurred while processing the payment."
          });
        }
      } catch (error) {
        console.error("Error handling BHIM QR:", error);
        setErrorModal({
          open: true,
          message: "An error occurred while processing the BHIM QR payment."
        });
      }
    } else {
      setErrorModal({
        open: true,
        message: "Unable to proceed with payment. Please check your network and try again."
      });
    }
  };
  
  const checkPaymentStatus = useCallback(async (orderId) => {
    try {
      const response = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/paymentVerificationIcici?orderId=${orderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      // console.log("API Response:", data); // Log the response for debugging

      if (response.ok && data.status) {
        // Transaction Successful
        if (data.message === "Transaction Successful!!" && data.data && data.data.length > 0) {
          clearInterval(pollingInterval); // Stop polling on success
          setPollingInterval(null); // Clear the polling interval state
          setQrCodeModal({ open: false }); // Close the QR code modal

          // Extract NextDate from the first element of data.data array
          const nextDate = data.data[0]?.NextDate || "N/A";
          // Store nextDate in localStorage
          localStorage.setItem('nextDate', nextDate);
          // Set the receipt data including NextDate
          setQrCodeStatusModal({
            open: true,
            message: data.message || "Transaction Successful",
            data: data.data || [],
            nextDate, // Save NextDate from the API response
          });

          // Fetch receipt data
          const receiptResponse = await fetch(`https://staffnew.saharshsolutions.co.in:2083/pmcontroller/getAllByorderId?orderid=${orderId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (receiptResponse.ok) {
            const receiptData = await receiptResponse.json();
            if (receiptData.status && receiptData.data) {
              // console.log("Receipt data fetched:", receiptData.data); // Debug: Log fetched receipt data

              // Store receiptData in localStorage
              localStorage.setItem('receiptData', JSON.stringify(receiptData.data));

              // Update the state with the receipt data
              setQrCodeStatusModal((prevState) => ({
                ...prevState,
                receiptData: receiptData.data,
              }));
            }
          }
        }
        // Transaction Not Completed
        else if (data.message === "Transaction Not Completed") {
          clearInterval(pollingInterval); // Stop polling on failure
          setPollingInterval(null); // Clear the polling interval state
          setQrCodeModal({ open: false }); // Close the QR code modal
          setQrCodeStatusModal({
            open: true,
            message: data.message || "Transaction Not Completed",
            data: data.data || [], // Display any additional data if available
          });
        }
        // Transaction Pending
        else if (data.message === "Transaction Pending!!" && data.data && data.data.orderId) {
          // Continue polling, don't show modal for pending status
          setQrCodeStatusModal({
            open: false, // Modal stays closed during pending status
            message: data.message || "Transaction Pending",
            data: [],
          });
        }
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      clearInterval(pollingInterval); // Stop polling on error
      setPollingInterval(null); // Clear the polling interval state
      setQrCodeModal({ open: false }); // Close the QR code modal on error
      setQrCodeStatusModal({
        open: true,
        message: "An error occurred while verifying the payment.",
        data: [], // No data to display in error case
      });
    }
  }, [pollingInterval]);

  const downloadReceipt = () => {
    // Retrieve nextDate and receiptData from localStorage
    const nextExpiry = localStorage.getItem('nextDate') || "N/A";
    const receiptData = JSON.parse(localStorage.getItem('receiptData'));
  
    // Check if receiptData is valid
    if (!receiptData) {
      console.error("No receipt data found.");
      return;
    }
  
    const doc = new jsPDF();
  
    // Add header
    doc.setFontSize(18);
    doc.setTextColor(40);
    doc.setFont("helvetica", "bold");
    doc.text("Saharsh Solution Payment Receipt", 105, 20, null, null, "center");
  
    // Add a horizontal line under the header
    doc.setLineWidth(0.5);
    doc.line(20, 25, 190, 25);
  
    // Extract and format the data to be displayed
    const orderId = receiptData.orderId || "N/A";
    const amount = receiptData.finalAmount ? `${receiptData.finalAmount.toFixed(2)}` : "N/A";
    const transactionDate = receiptData.bankTransactionDate || "N/A";
  
    // Add metadata section
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    let metadataStartY = 35;
  
    const metadata = [
      ["Order ID", orderId],
      ["Transaction Date", transactionDate],
      ["Amount", amount],
    ];
  
    metadata.forEach(([key, value]) => {
      doc.text(`${key}:`, 20, metadataStartY);
      doc.text(value, 70, metadataStartY);
      metadataStartY += 10;
    });
  
    // Add a horizontal line under metadata
    doc.setLineWidth(0.2);
    doc.line(20, metadataStartY - 5, 190, metadataStartY - 5);
  
    // Add Vehicle Details section
    let vehicleStartY = metadataStartY + 10;
  
    doc.setFont("helvetica", "bold");
    doc.text("Vehicle Details", 20, vehicleStartY);
    vehicleStartY += 8;
  
    // Iterate over vehicleInfoDTOList to display multiple vehicles
    const vehicleList = receiptData.vehicleInfoDTOList || [];
    if (vehicleList.length === 0) {
      doc.setFont("helvetica", "normal");
      doc.text("No vehicles available", 20, vehicleStartY);
      vehicleStartY += 10;
    } else {
      doc.setFont("helvetica", "normal");
  
      vehicleList.forEach((vehicle, index) => {
        const formattedOldExpiryDate = vehicle.expiryDate
          ? new Date(vehicle.expiryDate).toISOString().split("T")[0]
          : "N/A";
  
        const vehicleData = [
          ["Vehicle Name", vehicle.deviceName || "N/A"],
          ["Old Expiry Date", formattedOldExpiryDate],
          ["Next Expiry", nextExpiry],
        ];
  
        vehicleData.forEach(([key, value]) => {
          doc.text(`${key}:`, 20, vehicleStartY);
          doc.text(value, 70, vehicleStartY);
          vehicleStartY += 10;
        });
  
        if (index < vehicleList.length - 1) {
          // Add a separator line between vehicles
          doc.line(20, vehicleStartY - 5, 190, vehicleStartY - 5);
          vehicleStartY += 5;
        }
      });
    }
  
    // Draw a border around the main content
    const contentStartY = 28;
    const contentHeight = vehicleStartY - contentStartY + 5;
    doc.setLineWidth(0.5);
    doc.rect(15, contentStartY, 180, contentHeight);
  
    // Add footer
    const footerY = vehicleStartY + 20;
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text("Thank you for your payment!", 105, footerY, null, null, "center");
  
    // Save the PDF
    doc.save("receipt.pdf");
  };

  // Start polling when the QR Code modal is open
  useEffect(() => {
    if (qrCodeModal.open && clientTxnId) {
      // Set an initial delay of 15 seconds before starting the polling
      const initialTimeoutId = setTimeout(() => {
        // Start polling every 5 seconds after the initial 15-second delay
        const intervalId = setInterval(() => {
          checkPaymentStatus(clientTxnId);
        }, 5000);
        setPollingInterval(intervalId);
      }, 15000);

      // Clean up both the timeout and interval when the modal is closed or the component unmounts
      return () => {
        clearTimeout(initialTimeoutId);
        clearInterval(pollingInterval);
      };
    }
  }, [qrCodeModal.open, clientTxnId, checkPaymentStatus, pollingInterval]);

  const closeQrCodeModal = () => {
    setQrCodeModal({ open: false });
    clearInterval(pollingInterval);
    setPollingInterval(null); // Clear the polling interval state
  };

  const [showModal, setShowModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [updatedRate, setUpdatedRate] = useState('');
  const [password, setPassword] = useState('');

  const handleEditClick = (device) => {
    setCurrentDevice(device);
    setUpdatedRate(device.rate); // Pre-fill modal input with current rate
    setPassword(''); // Clear password field
    setShowModal(true);
  };

  const handleSave = async () => {
    if (!password) {
      alert('Please enter a password.');
      return;
    }
  
    const { profilename } = currentDevice;
    const apiUrl = `https://staffnew.saharshsolutions.co.in:2083/pmcontroller/passwordVerification?amount=${updatedRate}&password=${encodeURIComponent(
      password
    )}&devicemodel=${profilename}`;
  
    try {
      setLoading(true);
      const response = await fetch(apiUrl, { method: 'GET' });
      const data = await response.json();
  
      if (response.ok && data.status === true && data.message === 'Success') {
        // Update the state with the new rate
        setAddedDevices((prevDevices) =>
          prevDevices.map((device) =>
            device.deviceid === currentDevice.deviceid
              ? { ...device, rate: updatedRate }
              : device
          )
        );
        alert('Rate updated successfully!');
        setShowModal(false); // Close modal after saving
      } else {
        alert(data.message || 'Failed to verify password.');
      }
    } catch (error) {
      console.error('Error during password verification:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <h1 style={{ textAlign: 'center', fontSize: '2rem', marginTop: '1rem' }}>Welcome to Saharsh Solution </h1>
      <h3 style={{ textAlign: 'center', fontSize: '2rem', marginTop: '1rem' }}>Yearly Recharge</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', gap: '1rem' }}>
        <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#4a5568' }}>Enter your vehicle number</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            placeholder="Enter vehicle number"
            onChange={(e) => setSearchKey(e.target.value)}
            style={{ border: '1px solid #d1d5db', borderRadius: '0.5rem', padding: '0.5rem 1rem', outline: 'none', fontSize: '0.875rem', boxShadow: '0 0 0 0.125rem #3b82f6', width: '12rem' }}
          />
        </div>
        <button
          style={{
            backgroundColor: '#38a169',
            color: '#fff',
            padding: '0.5rem 1.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: 'none',
            fontWeight: '600',
            fontSize: '1rem',
            boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
          }}
          onClick={openModal}
        >
          Search
        </button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', gap: '1rem' }}>
        <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#4a5568' }}>Enter your username</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            placeholder="Enter vehicle username"
            onChange={(e) => setSearchKey(e.target.value)}
            style={{ border: '1px solid #d1d5db', borderRadius: '0.5rem', padding: '0.5rem 1rem', outline: 'none', fontSize: '0.875rem', boxShadow: '0 0 0 0.125rem #3b82f6', width: '12rem' }}
          />
        </div>
        <button
          style={{
            backgroundColor: '#38a169',
            color: '#fff',
            padding: '0.5rem 1.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: 'none',
            fontWeight: '600',
            fontSize: '1rem',
            boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
          }}
          onClick={openModalforuser}
        >
          Search
        </button>
      </div>

      {error && (
        <div style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>
          {error}
        </div>
      )}

    <div style={{ position: 'relative', overflowX: 'auto', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)', borderRadius: '0.5rem', marginTop: '3.5rem' }}>
          <table style={{ width: '100%', fontSize: '0.875rem', textAlign: 'left', color: '#4a5568' }}>
            <thead style={{ fontSize: '0.75rem', color: '#4a5568', backgroundColor: '#f9fafb' }}>
              <tr>
                <th style={{ padding: '0.75rem 1rem' }}>Server</th>
                <th style={{ padding: '0.75rem 1rem' }}>Username</th>
                <th style={{ padding: '0.75rem 1rem' }}>Vehicle Registration Number</th>
                <th style={{ padding: '0.75rem 1rem' }}>Expiry Date</th>
                <th style={{ padding: '0.75rem 1rem' }}>Next Expiry</th>
                <th style={{ padding: '0.75rem 1rem' }}>Renewal Charges</th>
                <th style={{ padding: '0.75rem 1rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {addedDevices.map((device) => (
                <tr key={device.deviceid} style={{ backgroundColor: '#fff', borderBottom: '1px solid #e2e8f0' }}>
                  <td style={{ padding: '1rem', fontWeight: '600', color: '#1a202c', whiteSpace: 'nowrap' }}>{device.server}</td>
                  <td style={{ padding: '1rem' }}>{device.name}</td>
                  <td style={{ padding: '1rem' }}>{device.devicename}</td>
                  <td style={{ padding: '1rem' }}>{device.expiry}</td>
                  <td style={{ padding: '1rem' }}>{device.newExpiry}</td>
                  <td style={{ padding: '1rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <span>{device.rate}</span>
                    <button
                      style={{
                        padding: '0.25rem 0.5rem',
                        backgroundColor: '#3182ce',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '0.25rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleEditClick(device)}
                    >
                      Edit
                    </button>
                  </td>
                  <td style={{ padding: '1rem' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                      onClick={() => removeDeviceFromTable(device.deviceid)}
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f7fafc', borderTop: '1px solid #e2e8f0' }}>
                <td colSpan="6" style={{ padding: '1rem', fontWeight: '600', color: '#1a202c' }}>Total Amount</td>
                <td style={{ padding: '1rem', fontWeight: '600', color: '#1a202c' }}>{totalAmount}</td>
              </tr>
            </tbody>
          </table>

          {/* Modal for Editing Renewal Charges */}
          {showModal && (
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                padding: '2rem',
                borderRadius: '0.5rem',
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
              }}
            >
              <h3 style={{ marginBottom: '1rem', color: '#1a202c' }}>Edit Renewal Charges</h3>
              <input
                type="text"
                value={updatedRate}
                onChange={(e) => setUpdatedRate(e.target.value)}
                placeholder="Enter New Rate"
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  border: '1px solid #e2e8f0',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  border: '1px solid #e2e8f0',
                  borderRadius: '0.25rem',
                  marginBottom: '1rem',
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  onClick={() => setShowModal(false)}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: '#e2e8f0',
                    color: '#1a202c',
                    border: 'none',
                    borderRadius: '0.25rem',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  disabled={loading}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: loading ? '#ccc' : '#3182ce',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '0.25rem',
                    cursor: loading ? 'not-allowed' : 'pointer',
                  }}
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          )}

          {/* Modal Overlay */}
          {showModal && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 999,
              }}
              onClick={() => setShowModal(false)}
            />
          )}
        </div>


      {/* Notification Modal */}
      {notification && (
        <div style={{ position: 'fixed', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(55, 65, 81, 0.5)', zIndex: 1001 }}>
          <div style={{ backgroundColor: '#fff', padding: '1.5rem', borderRadius: '0.5rem', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)', maxWidth: '30rem', width: '100%' }}>
            <h2 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '1rem', textAlign: 'center' }}>Message</h2>
            <p style={{ fontSize: '1rem', color: '#4a5568', textAlign: 'center' }}>{notification}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <button
                onClick={() => setNotification('')}
                style={{
                  backgroundColor: '#38a169',
                  color: '#fff',
                  padding: '0.5rem 1.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
        <button onClick={handlePaymentNow}
          style={{
            backgroundColor: '#3182ce',
            color: '#fff',
            padding: '0.5rem 1.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: 'none',
            fontWeight: '600',
            fontSize: '1rem',
            boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
          }}
        >
          Payment Now
        </button>
      </div>

      {isPaymentModalOpen && (
        <div style={{ position: 'fixed', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(55, 65, 81, 0.5)' }}>
          <div style={{ backgroundColor: '#fff', padding: '1.5rem', borderRadius: '0.5rem', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)', maxWidth: '24rem', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '1rem', textAlign: 'center', flexGrow: 1 }}>
                Choose payment type
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginLeft: '1rem', cursor: 'pointer' }}
                onClick={() => setIsPaymentModalOpen(false)}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}>
              {/* BHIM QR Button */}
              <div style={{ textAlign: 'center' }}>
                <button
                  onClick={handleBhimQrClick}
                  style={{
                    backgroundColor: '#38a169',
                    color: '#fff',
                    padding: '0.75rem 2rem',
                    borderRadius: '0.5rem',
                    cursor: 'pointer',
                    border: 'none',
                    fontWeight: '600',
                    fontSize: '1rem',
                    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                  }}
                >
                  BHIM QR / UPI
                </button>
                <p style={{
                  fontSize: '0.85rem',
                  color: '#4A5568', // Light gray color
                  marginTop: '0.5rem',
                  fontWeight: '500',
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                }}>
                  0% Charges
                </p>
              </div>

              {/* Payment Gateway Button */}
              <div style={{ textAlign: 'center' }}>
                <button
                  onClick={handlePaymentGatewayClick}
                  style={{
                    backgroundColor: '#38a169',
                    color: '#fff',
                    padding: '0.75rem 2rem',
                    borderRadius: '0.5rem',
                    cursor: 'pointer',
                    border: 'none',
                    fontWeight: '600',
                    fontSize: '1rem',
                    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                  }}
                >
                  Payment Gateway / Net banking / Debit Card / Credit Card
                </button>
                <p style={{
                  fontSize: '0.85rem',
                  color: '#E53E3E', // Red color for extra charges
                  marginTop: '0.5rem',
                  fontWeight: '500',
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                }}>
                  2% Charges applicable
                </p>
              </div>

              {/* Generate Payment Link Button */}
              <div style={{ textAlign: 'center' }}>
                <button
                  onClick={handleGenerateLink}
                  style={{
                    backgroundColor: '#38a169',
                    color: '#fff',
                    padding: '0.75rem 2rem',
                    borderRadius: '0.5rem',
                    cursor: 'pointer',
                    border: 'none',
                    fontWeight: '600',
                    fontSize: '1rem',
                    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                  }}
                >
                  Generate Payment Link
                </button>
                <p style={{
                  fontSize: '0.85rem',
                  color: '#E53E3E', // Red color for extra charges
                  marginTop: '0.5rem',
                  fontWeight: '500',
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                }}>
                  2% Charges applicable
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {amountconfirmforgeneratelink && (
        <div style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(55, 65, 81, 0.5)',
          zIndex: 1001,
        }}>
          <div style={{
            position: 'relative',
            backgroundColor: '#fff',
            padding: '2rem',
            borderRadius: '0.75rem',
            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
            maxWidth: '24rem',
            width: '100%',
            textAlign: 'center',
          }}>
            {/* Close Button (Cross) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                cursor: 'pointer',
                color: '#4A5568',
              }}
              onClick={() => setamountconfirmforgeneratelink(false)}
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>

            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: '700',
              marginBottom: '1.5rem',
              color: '#2d3748',
            }}>Payment Summary</h2>

            {/* Amount Breakdown */}
            <div style={{
              marginBottom: '1.5rem',
              padding: '1.5rem',
              backgroundColor: '#f7fafc',
              borderRadius: '0.75rem',
              boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.05)',
              textAlign: 'left',
            }}>
              <p style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '0.75rem',
                color: '#2d3748',
              }}>
                Your total amount is:
              </p>
              <p style={{
                fontSize: '1.75rem',
                fontWeight: '700',
                marginBottom: '0.75rem',
                color: '#38a169',
              }}>
                ₹ {vehicleDetails.actualAmount.toFixed(2)}
              </p>

              <hr style={{
                margin: '1rem 0',
                border: 'none',
                borderTop: '1px solid #e2e8f0',
              }} />

              <p style={{
                fontSize: '1rem',
                fontWeight: '500',
                marginBottom: '0.5rem',
                color: '#718096',
              }}>
                After taxes and fees, your final amount is:
              </p>
              <p style={{
                fontSize: '1.75rem',
                fontWeight: '700',
                color: '#e53e3e',
              }}>
                ₹ {vehicleDetails.finalAmount.toFixed(2)}
              </p>
            </div>

            {/* Proceed to Pay Button */}
            <button
              onClick={() => {
                setamountconfirmforgeneratelink(false);
                setResponseModal({ open: true, message: responseModal.message, paymentLink: responseModal.paymentLink });
              }}
              style={{
                backgroundColor: '#3182ce',
                color: '#fff',
                padding: '0.75rem 2rem',
                borderRadius: '0.75rem',
                cursor: 'pointer',
                border: 'none',
                fontWeight: '600',
                fontSize: '1rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                marginTop: '1rem',
              }}
            >
              Proceed to Pay
            </button>
          </div>
        </div>
      )}


      {isModalOpen && (
        <div style={{ position: 'fixed', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(55, 65, 81, 0.5)', zIndex: 1000 }}>
          <div style={{ backgroundColor: '#fff', padding: '1.5rem', borderRadius: '0.5rem', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)', maxWidth: '24rem', width: '100%', maxHeight: '80vh', overflowY: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '1rem', textAlign: 'center', flexGrow: 1 }}>
                Your vehicle details
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginLeft: '1rem', cursor: 'pointer' }}
                onClick={closeModal}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
            {loading ? (
              <Loader />
            ) : (
              Array.isArray(deviceData) ? (
                deviceData.map((device) => (
                  <div key={device.deviceid} style={{ marginBottom: '1rem', borderBottom: '1px solid #e2e8f0', paddingBottom: '1rem' }}>
                    <strong> Select vehicle: </strong>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedDeviceIds.includes(device.deviceid)} // Bind checkbox to selected state
                        onChange={() => handleSelectDevice(device.deviceid)} // Handle checkbox toggle
                      />
                    </label>
                    <p><strong>Server:</strong> {device.server}</p>
                    <p><strong>Username:</strong> {device.name}</p>
                    <p><strong>Vehicle Registration Number:</strong> {device.devicename}</p>
                    <p><strong>Expiry Date:</strong> {device.expiry}</p>
                    <p><strong>Renewal Charges:</strong> {device.rate}</p>
                  </div>
                ))
              ) : deviceData ? (
                <div style={{ marginBottom: '1rem', borderBottom: '1px solid #e2e8f0', paddingBottom: '1rem' }}>
                  <p><strong>Server:</strong> {deviceData.server}</p>
                  <p><strong>Username:</strong> {deviceData.name}</p>
                  <p><strong>Vehicle Registration Number:</strong> {deviceData.devicename}</p>
                  <p><strong>Expiry Date:</strong> {deviceData.expiry}</p>
                  <p><strong>Renewal Charges:</strong> {deviceData.rate}</p>
                </div>
              ) : (
                <p>No devices found.</p>
              )
            )}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={addDeviceToTable}
                style={{
                  backgroundColor: '#38a169',
                  color: '#fff',
                  padding: '0.5rem 1.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                }}
              >
                Add to list
              </button>
            </div>
          </div>
        </div>
      )}
      {amountconfirmforgateway && (
        <div style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(55, 65, 81, 0.5)',
          zIndex: 1001,
        }}>
          <div style={{
            position: 'relative',
            backgroundColor: '#fff',
            padding: '2rem',
            borderRadius: '0.75rem',
            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
            maxWidth: '24rem',
            width: '100%',
            textAlign: 'center',
          }}>
            {/* Close Button (Cross) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                cursor: 'pointer',
                color: '#4A5568',
              }}
              onClick={() => setamountconfirmforgateway(false)}
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>

            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: '700',
              marginBottom: '1.5rem',
              color: '#2d3748',
            }}>Payment Summary</h2>

            {/* Display Vehicle Details */}
            <div style={{
              marginBottom: '1.5rem',
              padding: '1.5rem',
              backgroundColor: '#f7fafc',
              borderRadius: '0.75rem',
              boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.05)',
              textAlign: 'left',
            }}>
              <p style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '0.75rem', color: '#2d3748' }}>
                Your total amount is:
              </p>
              <p style={{
                fontSize: '1.75rem',
                fontWeight: '700',
                marginBottom: '0.75rem',
                color: '#38a169',
              }}>
                ₹ {vehicleDetails.actualAmount.toFixed(2)}
              </p>

              <hr style={{
                margin: '1rem 0',
                border: 'none',
                borderTop: '1px solid #e2e8f0',
              }} />

              <p style={{
                fontSize: '1rem',
                fontWeight: '500',
                marginBottom: '0.5rem',
                color: '#718096',
              }}>
                After taxes and fees, your final amount is:
              </p>
              <p style={{
                fontSize: '1.75rem',
                fontWeight: '700',
                color: '#e53e3e',
              }}>
                ₹ {vehicleDetails.finalAmount.toFixed(2)}
              </p>
            </div>

            {/* Proceed to Pay Button */}
            <button
              onClick={() => {
                setamountconfirmforgateway(false);
                setIsOpen(true);
              }}
              style={{
                backgroundColor: '#3182ce',
                color: '#fff',
                padding: '0.75rem 2rem',
                borderRadius: '0.75rem',
                cursor: 'pointer',
                border: 'none',
                fontWeight: '600',
                fontSize: '1rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                marginTop: '1rem',
              }}
            >
              Proceed to Pay
            </button>
          </div>
        </div>
      )}

      {/* Render the payment gateway only when isOpen is true */}
      {isOpen && (
        <SabpaisaPaymentGateway
          clientCode={clientCode}
          transUserName={transUserName}
          transUserPassword={transUserPassword}
          authkey={authkey}
          authiv={authiv}
          payerName={payerName}
          payerEmail={localStorage.getItem('payerEmail')}
          payerMobile={localStorage.getItem('payerMobile')}
          clientTxnId={clientTxnId}
          amount={vehicleDetails.finalAmount}
          payerAddress={payerAddress}
          callbackUrl={callbackUrl}
          isOpen={isOpen}
        />
      )}

      {/* Response Modal */}
      {responseModal.open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(55, 65, 81, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '1.5rem',
              borderRadius: '0.5rem',
              boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
              maxWidth: '30rem',
              width: '100%',
            }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '1rem',
                textAlign: 'center',
              }}
            >
              Payment Link
            </h2>
            <p
              style={{
                fontSize: '1rem',
                color: '#4a5568',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              {responseModal.message}
            </p>
            {responseModal.paymentLink && (
              <div style={{ textAlign: 'center', marginTop: '1rem', maxHeight: '400px', overflowY: 'auto', padding: '1rem', backgroundColor: '#f7fafc', borderRadius: '0.5rem', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)' }}>
                <p>Payment Link: <a href={responseModal.paymentLink} target="_blank" rel="noopener noreferrer">{responseModal.paymentLink}</a></p>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(responseModal.paymentLink);
                    alert('Copied!'); // Show alert on copy
                  }}
                  style={{
                    backgroundColor: '#3182ce',
                    color: '#fff',
                    padding: '0.5rem 1.5rem',
                    borderRadius: '0.5rem',
                    cursor: 'pointer',
                    border: 'none',
                    fontWeight: '600',
                    fontSize: '1rem',
                    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                  }}
                >
                  Copy Link
                </button>

                {/* Copy Text Message */}
                <div style={{ marginTop: '1rem', padding: '1rem', backgroundColor: '#1a202c', borderRadius: '0.5rem', color: '#fff' }}>
                  <p>Your text message:</p>
                  <p>"Here is your payment link: {responseModal.paymentLink}"</p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`Here is your payment link: ${responseModal.paymentLink}`);
                      alert('Text Message Copied!'); // Show alert on copy
                    }}
                    style={{
                      backgroundColor: '#3182ce',
                      color: '#fff',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                      border: 'none',
                      fontWeight: '600',
                      fontSize: '1rem',
                      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Copy Text Message
                  </button>
                </div>

                {/* Copy WhatsApp Message */}
                <div style={{ marginTop: '1rem', padding: '1rem', backgroundColor: '#1a202c', borderRadius: '0.5rem', color: '#fff' }}>
                  <p>WhatsApp Message:</p>
                  <p style={{ marginBottom: '0.5rem' }}>
                    "Please use this link to complete your payment: {responseModal.paymentLink} for the following devices:"
                  </p>
                  {addedDevices.map((device, index) => (
                    <p key={index} style={{ marginBottom: '0.5rem' }}>
                      "Username: {device.name}, Vehicle: {device.devicename}, Expiry Date: {device.expiry}, Renewal Charges: {device.rate}."
                    </p>
                  ))}
                  <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
                    Total Amount: {totalAmount}
                  </p>
                  <button
                    onClick={() => {
                      const message = `Please use this link to complete your payment: ${responseModal.paymentLink} for the following devices:\n` +
                        addedDevices.map(device =>
                          `Username: ${device.name}, Vehicle: ${device.devicename}, Expiry Date: ${device.expiry}, Renewal Charges: ${device.rate}.`
                        ).join('\n') +
                        `\nTotal Amount: ${totalAmount}`;
                      navigator.clipboard.writeText(message);
                      alert('WhatsApp Message Copied!'); // Show alert on copy
                    }}
                    style={{
                      backgroundColor: '#3182ce',
                      color: '#fff',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                      border: 'none',
                      fontWeight: '600',
                      fontSize: '1rem',
                      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Copy WhatsApp Message
                  </button>
                </div>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <button
                onClick={() => setResponseModal({ open: false, message: "", paymentLink: "" })}
                style={{
                  backgroundColor: '#38a169',
                  color: '#fff',
                  padding: '0.5rem 1.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Payment Status Modal */}
      {paymentStatusModal.open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(55, 65, 81, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '1.5rem',
              borderRadius: '0.5rem',
              boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
              maxWidth: '30rem',
              width: '100%',
            }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '1rem',
                textAlign: 'center',
              }}
            >
              Payment Status
            </h2>

            {/* Dynamically display the API message */}
            <p
              style={{
                fontSize: '1rem',
                color: '#4a5568',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              {paymentStatusModal.message}
            </p>

            {/* Show table only when the message is "Transaction Successful" */}
            {paymentStatusModal.message === "Transaction Successful" && paymentStatusModal.data && paymentStatusModal.data.length > 0 && (
              <>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: '1rem',
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Vehicle Name
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Next Expiry
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Renewal Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentStatusModal.data.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                          }}
                        >
                          {item.VehicleName}
                        </td>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                          }}
                        >
                          {item.NextDate}
                        </td>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                            textAlign: 'center',
                          }}
                        >
                          ✓
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Download Receipt Button */}
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                  <button
                    onClick={downloadReceiptforsabpaisa}
                    style={{
                      backgroundColor: '#3182ce',
                      color: '#fff',
                      padding: '0.5rem 1rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                      border: 'none',
                      fontWeight: '600',
                      fontSize: '1rem',
                      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Download Receipt
                  </button>
                </div>
              </>
            )}

            {/* Display the orderId for non-successful transactions */}
            {(paymentStatusModal.message !== "Transaction Successful" && paymentStatusModal.data && paymentStatusModal.data.orderId) && (
              <div
                style={{
                  fontSize: '1rem',
                  color: '#e53e3e', // Red color for errors
                  textAlign: 'center',
                  marginBottom: '1rem',
                }}
              >
                <p>Order ID: {paymentStatusModal.data.orderId}</p>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <button
                onClick={() => setPaymentStatusModal({ open: false, message: "", data: [] })}
                style={{
                  backgroundColor: '#38a169',
                  color: '#fff',
                  padding: '0.5rem 1.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {qrCodeModal.open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(55, 65, 81, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '2rem',
              borderRadius: '0.75rem',
              boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
              maxWidth: '22rem',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1.5rem',
            }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '0',
              }}
            >
              Scan QR Code to Pay
              Saharsh Solutions
            </h2>

            {/* Display QR Code */}
            <div ref={qrCodeRef}>
              <QRCodeCanvas value={qrCodeModal.upiLink} size={200} />
            </div>

            {/* Infinite Loader Below QR Code */}
            <div
              style={{
                border: '4px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '4px solid #3498db',
                width: '40px',
                height: '40px',
                animation: 'spin 1s linear infinite',
              }}
            />

            {/* Button to Copy QR Code */}
            <button
              onClick={downloadQRCodeAsImage}
              style={{
                backgroundColor: '#3182ce',
                color: '#fff',
                padding: '0.5rem 1.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                border: 'none',
                fontWeight: '600',
                fontSize: '1rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }}
            >
              Download QR Code
            </button>

            <button
              onClick={closeQrCodeModal}
              style={{
                backgroundColor: '#38a169',
                color: '#fff',
                padding: '0.5rem 1.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                border: 'none',
                fontWeight: '600',
                fontSize: '1rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <style>
        {`
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
      </style>

      {qrCodeStatusModal.open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(55, 65, 81, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '1.5rem',
              borderRadius: '0.5rem',
              boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
              maxWidth: '30rem',
              width: '100%',
            }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '1rem',
                textAlign: 'center',
              }}
            >
              Payment Status
            </h2>

            {/* Transaction Successful */}
            {qrCodeStatusModal.message === "Transaction Successful!!" && qrCodeStatusModal.data && qrCodeStatusModal.data.length > 0 && (
              <>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: '1rem',
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Vehicle Name
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Next Expiry
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          padding: '0.5rem',
                          borderBottom: '1px solid #e2e8f0',
                        }}
                      >
                        Renewal Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {qrCodeStatusModal.data.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                          }}
                        >
                          {item.VehicleName}
                        </td>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                          }}
                        >
                          {item.NextDate}
                        </td>
                        <td
                          style={{
                            padding: '0.5rem',
                            borderBottom: '1px solid #e2e8f0',
                            textAlign: 'center',
                          }}
                        >
                          ✓
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Download Receipt Button */}
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                  <button
                    onClick={() => downloadReceipt(qrCodeStatusModal.receiptData)}
                    style={{
                      backgroundColor: '#3182ce',
                      color: '#fff',
                      padding: '0.5rem 1rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                      border: 'none',
                      fontWeight: '600',
                      fontSize: '1rem',
                      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Download Receipt
                  </button>
                </div>
              </>
            )}

            {/* Transaction Not Completed */}
            {qrCodeStatusModal.message === "Transaction Not Completed" && qrCodeStatusModal.data && qrCodeStatusModal.data.orderId && (
              <div
                style={{
                  fontSize: '1rem',
                  color: '#e53e3e', // Red color for errors
                  textAlign: 'center',
                  marginBottom: '1rem',
                }}
              >
                <p>Order ID: {qrCodeStatusModal.data.orderId}</p>
              </div>
            )}

            {/* Handle unexpected messages or Transaction Pending */}
            {(qrCodeStatusModal.message === "Transaction Pending!!" ||
              (qrCodeStatusModal.message !== "Transaction Successful!!" &&
                qrCodeStatusModal.message !== "Transaction Not Completed")) && (
                <div
                  style={{
                    fontSize: '1rem',
                    color: '#e53e3e', // Red color for errors
                    textAlign: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <p>{qrCodeStatusModal.message}</p>
                </div>
              )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <button
                onClick={() => {
                  setQrCodeStatusModal({ open: false, message: "", data: [] });
                  clearInterval(pollingInterval); // Stop polling when modal is closed
                }}
                style={{
                  backgroundColor: '#38a169',
                  color: '#fff',
                  padding: '0.5rem 1.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '1rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {errorModal.open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(55, 65, 81, 0.5)',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '2rem',
              borderRadius: '0.75rem',
              boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
              maxWidth: '22rem',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1.5rem',
            }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                marginBottom: '0',
                color: '#e53e3e',  // Red color for error indication
              }}
            >
              Error
            </h2>

            {/* Display error message */}
            <p style={{ fontSize: '1rem', color: '#4a5568' }}>{errorModal.message}</p>

            {/* Close button */}
            <button
              onClick={() => setErrorModal({ open: false, message: '' })}
              style={{
                backgroundColor: '#38a169',
                color: '#fff',
                padding: '0.5rem 1.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                border: 'none',
                fontWeight: '600',
                fontSize: '1rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
